import { withRouter } from 'next/router';
import getConfig from 'next/config';
import { SWRConfig } from 'swr';

import { wrapper } from '@/store/store';
import { populateProfile } from '@/ducks/profile/profileActions';
import { buildCommonProps, sanitizeCommonProps } from '@/server/data/';
import { imageDataToMetaTags } from '@/lib/helpers/imageDataToMetaTags';
import { truncateWithEllipsis } from '@/lib/helpers/strings';
import { fetchHostBadges, fetchObject } from '@/api/objectApi';
import { USE_OBJECT_KEY } from '@/hooks/useObject';
import { DATA_KEY as HOST_BADGES_DATA_KEY } from '@/hooks/useHostBadges';
import { DATA_KEY as AVAILABILITY_KEY } from '@/hooks/useAvailabilityPerDate';
import { DATA_KEY as CURRENT_CANCELLATION_POLICY_KEY } from '@/hooks/useCurrentCancellationPolicy';
import { createFetchKey as createAvailabilityInfoKey, fetchAvailabilityForPrivateForServer } from '@/hooks/useAvailabilityForPrivate';

import { Head } from 'shared/page/Head';
import { Main } from 'shared/page/Main';
import { ObjectPage } from '@/scenes/objectPage/ObjectPage';
import { checkValidDatesSsr } from '@/api/transactionApi';
import { fetchAvailabilityPerDateSsr, getCurrentCancellationPolicy } from '@/api/api';
import type { CancellationPolicy } from '@/models/cancellationPolicy';
import { FavoriteApiProvider } from '@/context/FavoriteApiProvider';

const {
    publicRuntimeConfig: { baseUrl },
} = getConfig();

const Ad = (props) => {
    wrapper.useHydration(props);
    const objectData = props.swrFallback[USE_OBJECT_KEY];
    const imageMetaTags = imageDataToMetaTags(objectData.images?.[0]);
    const truncatedDescription = truncateWithEllipsis(objectData.description, 200) ?? '';

    return (
        <SWRConfig value={{ fallback: props.swrFallback || null }}>
            <Head
                title={`${objectData.heading} | FINN reise`}
                description={truncatedDescription}
                canonical={`${baseUrl}/reise/feriehus-hytteutleie/ad.html?finnkode=${objectData.adId}`}
                imageUrl={imageMetaTags ? imageMetaTags.og.url : undefined}>
                {imageMetaTags !== null && (
                    <>
                        <meta property="og:image:alt" content={imageMetaTags.og.description} />
                        <meta name="twitter:image" content={imageMetaTags.twitter.url} />
                    </>
                )}
                <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/lightbox-gallery.min.css" />
                <link rel="stylesheet" href="https://static.finncdn.no/_c/troika-css/v8.4.25/components/imageformat.min.css" />
            </Head>
            <Main broadcastUrl="/broadcasts?path=www.finn.no/reise/feriehus-hytteutleie">
                <FavoriteApiProvider loginId={props.loginId}>
                    <ObjectPage {...props} />
                </FavoriteApiProvider>
            </Main>
        </SWRConfig>
    );
};

export const getServerSideProps = wrapper.getServerSideProps((store) => async ({ req, res, query }) => {
    const commonProps = await buildCommonProps(req, res, query);

    const adId = typeof query?.finnkode === 'string' ? query.finnkode : '';
    const objectData = await fetchObject(adId, commonProps.authCookies, req.cookies.USERID)
        .then((data) => data)
        .catch(() => null);

    if (!objectData) {
        return { notFound: true };
    }

    let hostBadgesData = {};
    if (objectData.orgId) {
        hostBadgesData = (await fetchHostBadges(objectData.orgId, commonProps.authCookies, req.cookies.USERID).catch(() => null)) || {};
    }

    const availabilityInfoKey = createAvailabilityInfoKey(Number(adId));
    const availabilityForPrivate = await fetchAvailabilityForPrivateForServer({ adId: Number(adId) }).catch(() => null);
    const availabilityPerDate = await fetchAvailabilityPerDateSsr({ itemId: Number(adId) });
    let currentCancellationPolicy: CancellationPolicy | null = null;
    const norwegianAddress = objectData?.location?.countryCode === 'NO';
    if (objectData.isPrivate && norwegianAddress) {
        currentCancellationPolicy = await getCurrentCancellationPolicy(Number(adId));
    }
    const swrFallback = {
        [availabilityInfoKey]: availabilityForPrivate,
        [AVAILABILITY_KEY]: availabilityPerDate,
        [USE_OBJECT_KEY]: objectData,
        [HOST_BADGES_DATA_KEY]: hostBadgesData,
        [CURRENT_CANCELLATION_POLICY_KEY]: currentCancellationPolicy,
    };

    let overrideLoginProps = {};
    if (process.env.MODULE_TEST === 'true') {
        overrideLoginProps = { loginId: 123456789 };
        await store.dispatch(populateProfile(123456789));
    } else {
        if (objectData.isPrivate) {
            const loginId = objectData.orgId;
            await store.dispatch(populateProfile(loginId));
        }
    }

    const from = typeof query?.fra === 'string' ? query.fra : null;
    const to = typeof query?.til === 'string' ? query.til : null;
    const validDates = from && to ? await checkValidDatesSsr(adId, from, to) : false;

    return {
        props: {
            ...sanitizeCommonProps(commonProps),
            ...overrideLoginProps,
            swrFallback: swrFallback,
            validDates,
        },
    };
});

// Move router into the component using it as a useRouter hook
export default withRouter(Ad);
